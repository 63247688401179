import { useRef } from "react";
import { RecorderProvider } from "../context/RecorderContext";
import { RendererProvider } from "../context/RendererContext";
import VocalCordSimulator from "./VocalCordSimulator";

export const App = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    return (
        <RecorderProvider>
            <RendererProvider canvasRef={canvasRef}>
                <VocalCordSimulator>
                    <canvas ref={canvasRef} style={{ display: "block" }} />
                </VocalCordSimulator>
            </RendererProvider>
        </RecorderProvider>
    );
};
