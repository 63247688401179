import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import * as THREE from "three";
import { WebGLRenderer } from "three";

const RendererContext = createContext<
    | {
          render: () => void;
          leftVocalFold: THREE.Mesh;
          rightVocalFold: THREE.Mesh;
      }
    | undefined
>(undefined);

export const RendererProvider = ({
    canvasRef,
    children,
}: PropsWithChildren<{ canvasRef: React.RefObject<HTMLCanvasElement> }>) => {
    const [renderer, setRenderer] = useState<WebGLRenderer | null>();

    useEffect(() => {
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasRef.current!,
            antialias: true,
        });

        renderer.setSize(window.innerWidth * 0.98, window.innerHeight * 0.98);
        setRenderer(renderer);
    }, [canvasRef]);

    const geometry = new THREE.PlaneGeometry(0.05, 0.5);
    const material = new THREE.MeshStandardMaterial({
        color: 0xff6347,
        roughness: 0.7,
        metalness: 0.2,
    });

    const leftVocalFold = new THREE.Mesh(geometry, material);
    leftVocalFold.position.x = -0.05;

    const rightVocalFold = new THREE.Mesh(geometry, material);
    rightVocalFold.position.x = 0.05;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );

    scene.add(leftVocalFold);
    scene.add(rightVocalFold);

    const light = new THREE.PointLight(0xffffff, 1, 100);
    light.position.set(0, 0, 2);
    scene.add(light);
    camera.position.z = 2;

    return (
        <RendererContext.Provider
            value={{
                render: () => renderer && renderer.render(scene, camera),
                leftVocalFold,
                rightVocalFold,
            }}
        >
            {children}
        </RendererContext.Provider>
    );
};

export const useRenderer = () => {
    const context = useContext(RendererContext);
    if (!context) {
        throw new Error("useRenderer must be used within a RenderProvider");
    }

    return context;
};
