import { PropsWithChildren, useEffect, useRef } from "react";
import { useRecorder } from "../context/RecorderContext";
import { useRenderer } from "../context/RendererContext";

const VocalCordSimulator = ({ children }: PropsWithChildren) => {
    const animationRef = useRef<number | null>(null);

    const { leftVocalFold, rightVocalFold, render } = useRenderer();
    const { meydaAnalyzer } = useRecorder();

    useEffect(() => {
        const startAnimation = () => {
            const animate = () => {
                animationRef.current = requestAnimationFrame(animate);

                if (!meydaAnalyzer) {
                    render();
                    return;
                }

                const features = meydaAnalyzer.get();

                if (features?.rms !== undefined) {
                    const opening = Math.max(0.01, features.rms * 2);
                    leftVocalFold.position.x = -0.05 - opening;
                    rightVocalFold.position.x = 0.05 + opening;
                }

                render();
            };

            animate();
        };

        startAnimation();

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [leftVocalFold, rightVocalFold, meydaAnalyzer, render]);

    return <>{children}</>;
};

export default VocalCordSimulator;
