import Meyda from "meyda";
import { MeydaAnalyzer } from "meyda/dist/esm/meyda-wa";
import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

type RecorderContextType = {
    meydaAnalyzer: MeydaAnalyzer | undefined;
};

const RecorderContext = createContext<RecorderContextType | undefined>(
    undefined
);

export const RecorderProvider = ({ children }: PropsWithChildren<{}>) => {
    const audioContextRef = useRef<AudioContext | null>(null);

    const [meydaAnalyzer, setMeydaAnalyzer] = useState<
        MeydaAnalyzer | undefined
    >();

    useEffect(() => {
        const setupAudioProcessing = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });

                if (!audioContextRef.current) {
                    audioContextRef.current = new AudioContext();
                }
                const source =
                    audioContextRef.current.createMediaStreamSource(stream);

                const analyzer = Meyda.createMeydaAnalyzer({
                    audioContext: audioContextRef.current,
                    source,
                    bufferSize: 512,
                    featureExtractors: ["rms"],
                });

                analyzer.start();

                setMeydaAnalyzer(analyzer);
            } catch (err) {
                console.error("Microphone access error:", err);
            }
        };

        setupAudioProcessing();

        return () => {
            if (meydaAnalyzer) {
                meydaAnalyzer.stop();
            }

            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
        // eslint-disable-next-line
    }, []);

    return (
        <RecorderContext.Provider value={{ meydaAnalyzer }}>
            {children}
        </RecorderContext.Provider>
    );
};

export const useRecorder = () => {
    const context = useContext(RecorderContext);
    if (!context) {
        throw new Error("useRecorder must be used within a RecorderProvider");
    }

    return context;
};
